<template>
  <div>
    <!-- Task Card Header -->
    <!-- TODO: uncomment this if material now have overview -->
    <!-- <b-card class="card mb-1" title="Materi Kelas">
      <div class="mt-2">
        <b-row>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Nama Materi</label>
            <b-form-input
              id="classroom-name"
              v-model="formData.classroomName"
              placeholder="Masukkan Nama Kelas"
              :state="formErrors.classroomName.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.classroomName.state">
              {{ formErrors.classroomName.message }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Deskripsi Materi</label>
            <b-form-textarea
              id="textarea-no-resize"
              placeholder="Deskripsi Kelas"
              rows="3"
              no-resize
              v-model="formData.classroomDescription"
              :state="formErrors.classroomDescription.state"
            />
            <b-form-invalid-feedback
              v-show="!formErrors.classroomDescription.state"
            >
              {{ formErrors.classroomDescription.message }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i class="ti ti-device-floppy small-gap-right"> </i>
              Simpan
            </div>
          </b-button>

          <b-button variant="warning" class="float-right small-gap-right">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i class="ti ti-plus small-gap-right"> </i>
              Tambah Materi
            </div>
          </b-button>
        </div>
      </div>
    </b-card> -->

    <b-card class="min-height-screen">
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center ">
          <i class="text-primary ti ti-book-2 small-gap-right"></i>
          <h4 class="text-primary mb-0">Materi Kelas</h4>
        </div>

        <!-- CTA Button -->
        <div>
          <!-- TODO: enable delete all button -->
          <!-- <b-button variant="outline-danger" class="small-gap-right">
            <div>
              <i class="ti ti-trash small-gap-right" />
              Hapus Semua
            </div>
          </b-button> -->
          <b-button
            variant="primary"
            :to="{ name: checkAuthorizeRole('classroom-detail-material-add') }"
          >
            <div>
              <i class="ti ti-plus small-gap-right" />
              Tambah
            </div>
          </b-button>
        </div>
      </div>

      <!-- Material Data -->
      <div
        v-show="!isLoading && classroomContentMaterials.length !== 0"
        class="vertical-scrollable"
      >
        <b-row>
          <!-- Material List -->
          <b-col
            v-for="material in classroomContentMaterials"
            :key="material.id"
            xl="4"
            lg="6"
            md="6"
          >
            <!-- Video -->
            <b-card
              v-if="material.video_url !== null"
              :title="material.title"
              class="border-light-secondary"
              bg-variant="transparent"
            >
              <div class="my-1">
                <span class="">
                  {{ material.description }}
                </span>
              </div>
              <div class="img-overlay text-center">
                <b-card-img
                  class="preview-material"
                  :src="baseMaterialBgImage"
                  alt="Image"
                />
                <b-avatar class="preview-icon bg-white" variant="light-primary">
                  <i
                    class="card-icon ti ti-player-play"
                    @click="showVideoModal(material.video_url)"
                  />
                </b-avatar>
              </div>
              <div
                class="py-1 mt-1 border-top d-flex justify-content-between card-action"
              >
                <b-button
                  size="sm"
                  variant="outline-warning"
                  class="d-flex align-items-center"
                  @click="showEditModal(material)"
                >
                  <i class="ti ti-upload" />
                  <span class="small-gap-left">Reupload</span>
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                  @click="deleteMaterial(material.id)"
                >
                  <i class="ti ti-trash" />
                  <span class="small-gap-left">Hapus</span>
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                  @click="downloadMaterial(material.video_url, material.title)"
                >
                  <i class="ti ti-download" />
                  <span class="small-gap-left">Download</span>
                </b-button>
              </div>
            </b-card>

            <!-- File -->
            <b-card
              v-if="material.file !== null"
              class="border-light-secondary"
              :title="material.title"
              bg-variant="transparent"
            >
              <div class="my-1">
                <span class="">
                  {{ material.description }}
                </span>
              </div>
              <div class="img-overlay text-center">
                <b-card-img
                  class="preview-material"
                  :src="baseMaterialBgImage"
                  alt="Image"
                />
                <b-badge
                  class="preview-icon bg-white py-1 px-2"
                  :variant="`light-${fileType(getExtension(material.file))}`"
                >
                  <span class="card-icon ">
                    .{{ getExtension(material.file) }}
                  </span>
                </b-badge>
              </div>
              <div
                class="py-1 mt-1 border-top d-flex justify-content-between card-action"
              >
                <b-button
                  size="sm"
                  variant="outline-warning"
                  class="d-flex align-items-center"
                  @click="showEditModal(material)"
                >
                  <i class="ti ti-upload" />
                  <span class="small-gap-left">Reupload</span>
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                  @click="deleteMaterial(material.id)"
                >
                  <i class="ti ti-trash" />
                  <span class="small-gap-left">Hapus</span>
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                  :href="material.file"
                  target="_blank"
                >
                  <i class="ti ti-download" />
                  <span class="small-gap-left">Download</span>
                </b-button>
              </div>
            </b-card>

            <!-- Link -->
            <b-card
              v-if="material.url !== null"
              :title="material.title"
              class="border-light-secondary"
              bg-variant="transparent"
            >
              <div class="my-1">
                <span class="">
                  {{ material.description }}
                </span>
              </div>
              <div class="img-overlay ">
                <b-card-img
                  class="preview-material"
                  :src="baseMaterialBgImage"
                  alt="Image"
                />
                <b-avatar class="preview-icon bg-white" variant="light-primary">
                  <i
                    class="card-icon ti ti-link"
                    @click="redirectToLink(material.url)"
                  />
                </b-avatar>
              </div>
              <div
                class="py-1 mt-1 border-top d-flex justify-content-between card-action"
              >
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="d-flex align-items-center"
                  @click="deleteMaterial(material.id)"
                >
                  <i class="ti ti-trash" />
                  <span class="small-gap-left">Hapus</span>
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-info"
                  class="d-flex align-items-center"
                  @click="redirectToLink(material.url)"
                >
                  <span class="small-gap-right">Buka</span>
                  <i class="ti ti-chevron-right" />
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <!-- Empty State -->
      <div v-show="!isLoading && classroomContentMaterials.length === 0">
        <b-row>
          <b-col class="d-flex justify-content-center mt-4">
            <div class="text-center pt-2">
              <b-img class="illustration-small" fluid :src="emptyStateImg" />
              <p class="mb-2">
                Belum Ada Materi Kelas
              </p>
              <b-button
                variant="primary"
                class="mb-1 btn-sm-block"
                :to="{
                  name: checkAuthorizeRole('classroom-detail-material-add'),
                }"
              >
                Tambah Materi Kelas
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Loading Material -->
      <div v-if="isLoading" class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Daftar Materi
          </p>
        </div>
      </div>
    </b-card>

    <!-- Preview Modal -->
    <div>
      <b-modal
        id="modal-video-preview"
        centered
        size="lg"
        title="Preview Materi"
        ok-only
        ok-title="Tutup"
      >
        <div>
          <video
            class="rounded"
            autoplay
            controls
            width="100%"
            :src="videoURL"
          />
        </div>
      </b-modal>
    </div>

    <!-- Reupload Material Modal -->
    <classroom-material-reupload
      :material="selectedMaterial"
      :selected-material-type="selectedMaterialType"
      @refresh-material="refreshMaterial"
    />

    <!-- toast -->
    <b-toast id="toast-download-material" variant="primary" solid no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-25">
          <strong class="mr-auto">
            Mengunduh Materi...
            <b id="download-percentage-title">0</b>
            %
          </strong>
        </div>
      </template>
      <span>
        Proses Mengunduh, Mohon Tunggu ... ( <b id="download-percentage">0</b>%
        )
      </span>
    </b-toast>
  </div>
</template>

<script>
// ** Third Party Components
import axios from 'axios'

// Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { localizeISODate, fileType } from '@core/utils/filter'

// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BCardImg,
  BImg,
  BAvatar,
  BSpinner,
  BModal,
  BToast,
} from 'bootstrap-vue'
import ClassroomMaterialReupload from './classroom-material-reupload/ClassroomMaterialReupload.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardImg,
    BImg,
    BAvatar,
    BSpinner,
    BModal,
    BToast,

    ClassroomMaterialReupload,
  },
  data() {
    return {
      // state
      isLoading: true,

      // assets
      baseMaterialBgImage: require('@/assets/images/component/material/material-bg.png'),
      sampleVideoMaterialBgImage: require('@/assets/media/sample/video/sample-video.png'),
      sampleVideoMaterial: require('@/assets/media/sample/video/sample-video.mp4'),
      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),
      videoURL: '',

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      classroomContentMaterials: [],

      // reupload
      selectedMaterial: {},
      selectedMaterialType: 'file',
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = { id: to.params.id }
    next()
  },
  async mounted() {
    this.fetchMaterialsData()
  },
  methods: {
    checkAuthorizeRole,
    localizeISODate,
    fileType,
    getExtension(filename) {
      return filename.split('.').pop()
    },

    async fetchMaterialsData() {
      this.isLoading = true
      const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
      const url = `${baseUrl}/classrooms/${this.classroomId}`
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      await axios
        .get(url, config)
        .then((response) => {
          const materialDataByContentId = response.data.data.class_contents.filter(
            (content) => {
              return content.id === this.contentId
            }
          )

          this.classroomContentMaterials = [
            ...materialDataByContentId[0].materials,
          ]
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
        })
    },

    // Show preview modal
    showVideoModal(fileURL) {
      this.videoURL = fileURL
      this.$bvModal.show('modal-video-preview')
    },

    // Download
    downloadMaterial(fileURL, materialName) {
      this.$bvToast.show('toast-download-material', {
        solid: true,
        appendToast: true,
        toaster: 'b-toaster-bottom-right',
      })

      axios
        .get(fileURL, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const total = parseFloat(progressEvent.total)
            const current = progressEvent.loaded

            const percentCompleted = Math.floor((current / total) * 100)

            document.getElementById(
              'download-percentage'
            ).innerHTML = percentCompleted
            document.getElementById(
              'download-percentage-title'
            ).innerHTML = percentCompleted
          },
        })
        .then((response) => {
          const href = URL.createObjectURL(response.data)
          const extension = this.getExtension(fileURL)

          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', `${materialName}.${extension}`)
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)

          // hide toast
          this.$bvToast.hide('toast-download-material')
        })
    },

    // Edit
    showEditModal(material) {
      this.selectedMaterial = material
      if (material.file !== null) {
        this.selectedMaterialType = 'file'
      } else {
        this.selectedMaterialType = 'video'
      }

      this.$bvModal.show('modal-reupload')
    },

    // Delete
    deleteMaterial(materialId) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteMaterialRequest(materialId)
        }
      })
    },

    deleteMaterialRequest(materialId) {
      client
        .delete(
          `/classrooms/${this.classroomId}/class-contents/${this.contentId}/class-content-materials/${materialId}`
        )
        .then((response) => {
          this.$swal({
            icon: 'success',
            title: 'Berhasil!',
            text: 'Materi berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.go()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Gagal',
            text: 'Materi gagal dihapus.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // Redirect
    redirectToLink(link) {
      window.open(link, '_blank')
    },

    // refresh
    refreshMaterial() {
      console.log('triggered')
      this.fetchMaterialsData()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  height: 160px;
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  font-size: 2rem;
}

.vertical-scrollable {
  position: relative;
  padding-right: 15px;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.min-height-screen {
  min-height: calc(100vh - 190px);
}

.b-toaster-top-right {
  top: 10px !important;
}
</style>
