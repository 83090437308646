<template>
  <div>
    <b-modal
      id="modal-reupload"
      centered
      size="lg"
      title="Reupload Materi"
      ok-only
      ok-title="Tutup"
      @hidden="remove(0)"
    >
      <div>
        <!-- File Form Input -->
        <b-col
          v-show="selectedMaterialType === 'file' && filelist.length === 0"
          lg="12"
        >
          <div class="drop-file-con">
            <b-card
              class="drop-file p-4 mt-2"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="d-flex flex-column align-items-center">
                <b-avatar size="lg" rounded="sm" variant="light-primary">
                  <i class="card-icon-lg ti ti-file-upload"></i>
                </b-avatar>
                <h3 class="pt-1 small-gap-bottom">
                  Seret file di sini
                </h3>
                <span class="small-gap-bottom">
                  (Format file yang didukung: .pdf, .doc, .docx, .xls, .xlsx,
                  .ppt, .pptx)
                </span>
                <b-button variant="primary" @click="$refs.file.click()">
                  Upload File
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>

        <!-- Video Form Input -->
        <b-col
          v-show="selectedMaterialType === 'video' && filelist.length === 0"
          lg="12"
        >
          <div class="drop-file-con">
            <b-card
              class="drop-file p-4 mt-2"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="d-flex flex-column align-items-center">
                <b-avatar size="lg" rounded="sm" variant="light-info">
                  <i class="card-icon-lg ti ti-brand-zoom" />
                </b-avatar>
                <h3 class="pt-1 small-gap-bottom">
                  Seret file di sini
                </h3>
                <span class="small-gap-bottom">
                  (Format file yang didukung: .mp4, .mkv)
                </span>
                <b-button variant="info" @click="$refs.file.click()">
                  Upload File
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>

        <!-- Display Selected File -->
        <b-row v-show="selectedMaterialType !== 'link' && filelist.length != 0">
          <b-col lg="12 ">
            <label class="d-block">File / Video</label>
          </b-col>
          <b-col lg="4">
            <b-card
              v-for="(file, index) in filelist"
              :key="index"
              class="border-light-secondary d-flex flex-column text-center"
            >
              <b-button
                class="remove-button-x"
                variant="danger"
                size="sm"
                @click="remove(index)"
              >
                <i class="ti ti-x" />
              </b-button>
              <b-avatar rounded :variant="fileType(getExtension(file.name))">
                <span class="p-2"> {{ getExtension(file.name) }}</span>
              </b-avatar>
              <div class="mt-1">
                <span>
                  {{ ellipsis(file.name) }}
                </span>
              </div>
            </b-card>

            <!-- Hidden Input -->
            <input
              ref="file"
              hidden
              type="file"
              :accept="
                selectedMaterialType === 'file'
                  ? '.pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx'
                  : '.mp4, .mkv'
              "
              @change="onChange()"
            />
          </b-col>
        </b-row>

        <!-- Submit Button -->
        <div class="mt-1">
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-device-floppy small-gap-right"
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// * Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// * Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, fileType } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    BModal,

    vSelect,
    flatPickr,
  },
  props: {
    material: {
      type: Object,
      default: () => {},
    },
    selectedMaterialType: {
      type: String,
      default: 'file',
    },
  },
  data() {
    return {
      // state
      isLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,

      // form data
      filelist: [],
    }
  },
  methods: {
    avatarText,
    fileType,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    triggerFormInput(type) {
      this.selectedMaterialType = type

      if (type === 'link') {
        this.filelist = []
      } else {
        this.formData.materialURL = ''
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'mp4',
            'mkv',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },
    async submitForm() {
      this.isLoading = true

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // Create form data
      const data = new FormData()
      data.append('_method', 'PUT')
      data.append('title', this.material.title)
      data.append('description', this.material.description)

      // Check if material type is link
      if (this.selectedMaterialType !== 'file') {
        data.append('video_url', this.filelist[0])
      } else {
        data.append('file', this.filelist[0])
      }

      // create content material
      await client
        .post(
          `/classrooms/${this.classroomId}/class-contents/${this.contentId}/class-content-materials/${this.material.id}`,
          data,
          config
        )
        .then((response) => {
          // stop loading
          this.isLoading = false

          // show success toast
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Materi berhasil diubah'
          )

          // refresh data
          this.$emit('refresh-material')

          // close modal
          this.$bvModal.hide('modal-reupload')
        })
        .catch((error) => {
          this.isLoading = false

          // show success toast
          this.showToast(
            'danger',
            'XIcon',
            'Gagal',
            error.response.data.message
          )
        })
    },
  },
}
</script>

<style lang="scss">
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}

#modal-reupload {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
